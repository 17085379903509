import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/home/Home.vue"

Vue.use(VueRouter)

/* Router route */

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/contactus", //方案咨询
    name: "contactus",
    component: () => import("@/components/common/contactus"),
  },
  {
    path: "/case", //合作案例
    name: "CooperationCase",
    component: () => import("@/views/cooperationCase/CooperationCase"),
  },
  {
    path: "/case/detail", //合作案例详情
    name: "CooperationCaseDetail",
    component: () => import("@/views/cooperationCase/CooperationCaseDetail"),
  },
  // 开发能力
  {
    path: "/tech", //开发能力
    name: "tech",
    component: () => import("@/views/tech/tech"),
  },

  // 企业地图服务
  {
    path: "/mapServices",
    name: "mapServices",
    component: () => import("@/views/mapServices/mapServices"),
  },

  // 价格
  {
    path: "/price",
    name: "price",
    component: () => import("@/views/price/price"),
  },

  // 模版
  {
    path: "/template",
    name: "template",
    component: () => import("@/views/template/template"),
  },

  // 解决方案
  {
    path: "/solution", //解决方案
    name: "solution",
    component: () => import("@/views/solution/solution"),
  },

  {
    path: "/aboutUs", //解决方案
    name: "aboutUs",
    component: () => import("@/views/aboutUs/aboutUs"),
  },


  // 校车
  {
    path: "/schoolBus", //校车
    name: "schoolBus",
    component: () => import("@/views/schoolBus/schoolBus"),
  },
  // TMS
  {
    path: "/TMS", //校车
    name: "TMS",
    component: () => import("@/views/tms/tms"),
  },
  //智慧养老
  {
    path: "/pension", //校车
    name: "pension",
    component: () => import("@/views/pension/pension"),
  },
  //服务与支持
  {
    path: "/service/doc", //帮助文档  helpdocument
    name: "HelpDocument",
    component: () =>
      import("@/views/servicesAndSupport/helpDocument/HelpDocument"),
    children: [
      {
        path: "detail", // 产品--- ios工程配置常见问题
        name: "Ios",
        component: () =>
          import("@/views/servicesAndSupport/helpDocument/product/Ios"),
      },
      {
        path: "",
        redirect: "/service/doc/detail",
      },
    ],
  },
  // {
  //   path: "/service/helpdocument/:id",//产品--- ios工程配置常见问题
  //   name: "Ios",
  //   component: () => import("@/views/servicesAndSupport/helpDocument/product/Ios")
  // },

  {
    path: "/service/tool", //工具导航  toolnavigation
    name: "ToolNavigation",
    component: () =>
      import("@/views/servicesAndSupport/toolNavigation/ToolNavigation"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
