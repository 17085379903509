<template>
  <div class="footer">
    <div class="type-area">
      <div class="footer-title">企业专属全要素地图</div>
      <div class="footer-main">
        <div class="footer-item">
          <div class="item-title">产品功能</div>
          <div><a href="#">企业地图服务</a></div>
          <div><a href="#">时空数据服务</a></div>
          <div><a href="#">空间计算引擎</a></div>
          <div><a href="#">时空AI引擎</a></div>
          <div><a href="#">低代码平台</a></div>
        </div>
        <div class="footer-item">
          <div class="item-title">解决方案</div>
          <div><a href="#">智慧园区</a></div>
          <div><a href="#">智慧治理</a></div>
          <div><a href="#">智慧工地</a></div>
          <div><a href="#">人员安防</a></div>
          <div><a href="#">应急调度</a></div>
        </div>
        <div class="footer-item">
          <div class="item-title">服务与支持</div>
          <div><a href="#">帮助中心</a></div>
          <div><a href="#">开放平台</a></div>
          <div><a href="#">开发文档</a></div>
          <div><a href="#">SDK下载</a></div>
          <div><a href="#">全流程定制</a></div>
          <div>
            <a
              target="_blank"
              href="https://a845cams7p.feishu.cn/wiki/ULzqw3P2uiYsO4kI0ThcK2AWnAi"
            >
              更新日志
            </a>
          </div>
        </div>

        <div class="footer-item">
          <div class="item-title">关于我们</div>
          <div><a href="#">公司动态</a></div>
          <div><a href="#">合作咨询</a></div>
          <div><a href="#">联系我们</a></div>
          <div><a href="#">加入我们</a></div>
        </div>

        <div class="footer-item">
          <div class="item-title">联系我们</div>
          <div><a href="#">咨询电话：13980789940</a></div>
          <div><a href="#">客服邮箱：kefu@hongxiangai.com</a></div>
          <div><a href="#">公司地址：成都市高新区天府软件园B区7栋703室</a></div>

          <div class="qrcodes">
            <img src="./images/footer/weixinma_1.png" alt="" />
            <img src="./images/footer/weixinma_2.png" alt="" />
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div>
          © 2016-2023 鸿象版权所有
          <a href="https://beian.miit.gov.cn/">蜀ICP备2022022764号-2</a>
        </div>
        <div><span>法律声明</span> | <span>隐私政策</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.footer {
  height: 602px;
  background: #0272c9;
  overflow: hidden;
  .type-area {
    width: 1180px;
    margin: 0 auto;
    .footer-title {
      margin-top: 63px;
      font-size: 42px;
      line-height: 58px;
      color: #fff;
      position: relative;
      // &::after {
      //   content: '“';
      //   position: absolute;
      //   font-size: 72px;
      //   left: -30px;
      // }
    }
    .footer-main {
      display: flex;
      justify-content: space-between;
      div {
        font-size: 14px;
        line-height: 20px;
        margin-top: 17px;
        a {
          color: rgba(255, 255, 255, 0.58);
          &:hover {
            color: #fff;
          }
        }
        &:nth-child(2) {
          margin-top: 24px;
        }
        &.qrcodes {
          margin-top: 32px;
          width: 195px;
          display: flex;
          justify-content: space-between;
          img {
            width: 92px;
            height: 92px;
          }
        }
      }
      .item-title {
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        margin-top: 68px;
      }
      .footer-item {
        margin-top: 0 !important;
      }
    }
    .footer-bottom {
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      margin-top: 36px;
      padding-top: 35px;
      box-sizing: border-box;
      color: rgba(255, 255, 255, 0.58);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      & > div:last-child {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.58);
        span {
          &:hover {
            color: #ebedf1;
          }
        }
      }
      div a {
        color: rgba(255, 255, 255, 0.58);
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
