import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
// import { Upload, Button, Icon } from "ant-design-vue";
import "element-ui/lib/theme-chalk/index.css"
import "./assets/style/style.css"
import "./assets/js/public.js"
import "./icons/index"
import "swiper/dist/css/swiper.css"
import VueAwesomeSwiper from "vue-awesome-swiper"
// import echarts from "echarts";
import { post, fetch, put, remove } from "./assets/js/requset.js"


import './assets/iconfont/iconfont.css'


import './assets/style/harmony.css'

Vue.use(ElementUI)
// Vue.use(Upload);
// Vue.use(Button);
// Vue.use(Icon);
Vue.use(VueAwesomeSwiper)
// Vue.prototype.$echarts = echarts;
Vue.prototype.$post = post
Vue.prototype.$fetch = fetch
Vue.prototype.$put = put
Vue.prototype.$remove = remove
Vue.config.productionTip = false
console.log(process.env.VUE_APP_BASE_API)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
