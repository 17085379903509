<template>
  <div class="head">
    <div style="width: 100%; min-width: 900px">
      <div class="haedr">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#000"
          active-text-color="#fff"
          :router="true"
        >
          <el-menu-item index="/">
            <img src="@/assets/images/hongxiang.png" alt="" />
          </el-menu-item>
          <el-menu-item index="/" id="index"> 首页 </el-menu-item>

          <!-- <el-menu-item class="cooperation" index="/case"> -->
          <!-- <el-menu-item class="cooperation">
            适用场景
            <div class="cooperationbox">
              <div class="cooperationline">
                <div class="cooperationimg">
                  <img
                    src="@/assets/images/iconmenu_renyuanguanli.png"
                    alt=""
                  />
                </div>
                <div class="line-title">人员管理</div>
                <div class="line-item">实时音视频</div>
                <div class="line-item">人员定位</div>
                <div class="line-item">实名制管理</div>
              </div>
              <div class="cooperationline">
                <div class="cooperationimg">
                  <img
                    src="@/assets/images/iconmenu_anfangjiankong.png"
                    alt=""
                  />
                </div>
                <div class="line-title">安防监控</div>
                <div class="line-item">视频监控地图</div>
                <div class="line-item">场地巡查</div>
                <div class="line-item">智能门禁</div>
                <div class="line-item">事件管理</div>
                <div class="line-item">隐患地图</div>
                <div class="line-item">风险地图</div>
              </div>
              <div class="cooperationline">
                <div class="cooperationimg">
                  <img
                    src="@/assets/images/iconmenu_zhinengwulian.png"
                    alt=""
                  />
                </div>
                <div class="line-title">智能物联</div>
                <div class="line-item">车辆管理</div>
                <div class="line-item">固定资产地图</div>
                <div class="line-item">故障报修管理</div>
              </div>
              <div class="cooperationline">
                <div class="cooperationimg">
                  <img src="@/assets/images/iconmenu_zhuantiditu.png" alt="" />
                </div>
                <div class="line-title">专题地图</div>
                <div class="line-item">客户地图</div>
                <div class="line-item">订单地图</div>
                <div class="line-item">网点地图</div>
                <div class="line-item">合同地图</div>
                <div class="line-item">广告位地图</div>
                <div class="line-item">智能分单</div>
              </div>
              <div class="cooperationline">
                <div class="cooperationimg">
                  <img
                    src="@/assets/images/iconmenu_huanjingganzhi.png"
                    alt=""
                  />
                </div>
                <div class="line-title">环境感知</div>
                <div class="line-item">实时天气</div>
                <div class="line-item">用水用电</div>
                <div class="line-item">绿色施工</div>
              </div>
              <div class="cooperationline">
                <div class="cooperationimg">
                  <img src="@/assets/images/iconmenu_oa.png" alt="" />
                </div>
                <div class="line-title">OA办公</div>
                <div class="line-item">打卡签到</div>
                <div class="line-item">流程审批</div>
              </div>
              <div class="cooperationline">
                <div class="cooperationimg">
                  <img src="@/assets/images/iconmenu_qitayingyong.png" alt="" />
                </div>
                <div class="line-title">更多应用</div>
                <div class="line-item">在线课堂</div>
                <div class="line-item">考试中心</div>
                <div class="line-item">电商系统</div>
              </div>
            </div>
          </el-menu-item> -->

          <el-menu-item class="productFeatures">
            产品功能
            <div class="productFeaturesbox">
              <div
                v-for="item in productFeaturesList"
                :key="item.id"
                :disabled="item.router ? false : true"
                @click="goRouter(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </el-menu-item>

          <el-menu-item class="solution" index="/solution">
            解决方案

            <div class="solutionbox">
              <!-- <router-link to="/schoolBus">智慧校车</router-link>
              <router-link to="/TMS">运输管理TMS</router-link>
              <router-link to="/pension">智慧养老</router-link> -->

              <div class="leftbox">
                <div
                  v-for="item in solutionList"
                  :key="item.id"
                  :select="item.id == selectSolutionId ? true : false"
                  @click.stop="selectSolution(item)"
                >
                  {{ item.title }}
                </div>
              </div>
              <div class="rightbox">
                <div
                  v-for="item in solutionList"
                  :key="'right' + item.id"
                  :style="{
                    display: item.id == selectSolutionId ? 'flex' : 'none',
                  }"
                >
                  <div
                    v-for="itemChildren in item.children"
                    :key="itemChildren.id"
                    @click.stop="goRouter(itemChildren)"
                    :disabled="itemChildren.router ? false : true"
                  >
                    {{ itemChildren.title }}
                  </div>
                </div>
              </div>
            </div>
          </el-menu-item>

          <el-menu-item index="/template"> 模板中心 </el-menu-item>
          <!-- <el-menu-item> 模板中心 </el-menu-item> -->

          <el-menu-item index="/price"> 价格 </el-menu-item>

          <el-menu-item index="/aboutUs"> 关于我们 </el-menu-item>

          <!-- <el-menu-item class="openability" index="/tech">
            开放能力
            <div class="openabilitybox">
              <div class="openabilityline">
                <div class="openabilityimg">
                  <img src="@/assets/images/iconmenu_gis.png" alt="" />
                </div>
                <div class="line-title">GIS服务</div>
                <div class="line-item">底图加载</div>
                <div class="line-item">实时定位</div>
                <div class="line-item">要素搜索</div>
                <div class="line-item">路径规划</div>
                <div class="line-item">地理围栏</div>
                <div class="line-item">地址管理</div>
                <div class="line-item">数据可视化</div>
              </div>
              <div class="openabilityline">
                <div class="openabilityimg">
                  <img src="@/assets/images/iconmenu_didaima.png" alt="" />
                </div>
                <div class="line-title">低代码</div>
                <div class="click">时空流程引擎</div>
                <div class="line-item">表单引擎</div>
                <div class="line-item">标绘组件</div>
              </div>
              <div class="openabilityline">
                <div class="openabilityimg">
                  <img
                    src="@/assets/images/iconmenu_zhinengwulian.png"
                    alt=""
                  />
                </div>
                <div class="line-title">物联网</div>
                <div class="line-item">融合通信</div>
                <div class="line-item">混合定位</div>
                <div class="line-item">一物一码</div>
              </div>
              <div class="openabilityline">
                <div class="openabilityimg">
                  <img src="@/assets/images/iconmenu_xitonganquan.png" alt="" />
                </div>
                <div class="line-title">系统安全</div>
                <div class="line-item">API网关</div>
                <div class="line-item">单点登录集成</div>
              </div>
              <div class="openabilityline">
                <div class="openabilityimg">
                  <img
                    src="@/assets/images/iconmenu_jichugongneng1.png"
                    alt=""
                  />
                </div>
                <div class="line-title">基础功能</div>
                <div class="line-item">全局搜索</div>
                <div class="line-item">消息引擎</div>
                <div class="line-item">用户系统</div>
                <div class="line-item">文档编辑器</div>
              </div>
              <div class="openabilityline">
                <div class="openabilityimg">
                  <img src="@/assets/images/iconmenu_gongju.png" alt="" />
                </div>
                <div class="line-title">工具</div>
                <div class="line-item">标注</div>
                <div class="line-item">坐标拾取</div>
                <div class="line-item">资产数据管理</div>
                <div class="line-item">标准地址管理</div>
              </div>
            </div>
          </el-menu-item> -->

          <!-- <el-submenu
            index=""
            id="servicesAndSupport"
            :popper-append-to-body="true"
          >
            <template slot="title"
              >服务与支持</template
            >
            <el-menu-item index="/service/tool">工具导航</el-menu-item>
            <el-menu-item index="/service/doc">文档中心</el-menu-item>
          </el-submenu> -->
        </el-menu>
      </div>
      <div class="search">
        <!-- <i class="iconfont iconsousuo"></i> -->
        <!-- <span>139-8078-9940</span> -->
        <!-- <span></span> -->

        <div class="language">
          <span class="active">CH</span>
          <span>EN</span>
        </div>

        <span></span>

        <div class="login">
          <span>登录</span>
          <span>/</span>
          <span>注册</span>
        </div>
      </div>
      <!-- <transition name="slide-fade">
        
      </transition> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeoutID: null,
      activeIndex: "/",

      productFeaturesList: [
        {
          id: "product-1",
          title: "企业地图服务",
          router: "/mapServices",
        },

        {
          id: "product-2",
          title: "时空数据服务",
        },

        {
          id: "product-3",
          title: "空间计算引擎",
        },

        {
          id: "product-4",
          title: "时空AI引擎",
        },

        {
          id: "product-5",
          title: "低代码平台",
          router: "/tech",
        },
      ],

      selectSolutionId: "1",
      solutionList: [
        {
          id: "1",
          title: "行业解决方案",
          children: [
            {
              id: "1-1",
              title: "供应链优化",
              router: "/TMS",
            },
            {
              id: "1-2",
              title: "校车管控",
            },
            {
              id: "1-3",
              title: "社区养老",
            },
            {
              id: "1-4",
              title: "外卖订单分析",
            },
            {
              id: "1-5",
              title: "客户分布",
            },
            {
              id: "1-6",
              title: "资产管理",
            },
            {
              id: "1-7",
              title: "物联网地图",
            },
            {
              id: "1-8",
              title: "视频监控地图",
            },
            {
              id: "1-9",
              title: "应急救援",
            },
            {
              id: "1-10",
              title: "园区安防",
            },
            {
              id: "1-11",
              title: "设备巡检",
            },
            {
              id: "1-12",
              title: "地理营销",
            },
          ],
        },
        {
          id: "2",
          title: "通用解决方案",
          children: [
            {
              id: "2-1",
              title: "企业专属地图",
            },
            {
              id: "2-2",
              title: "地图插件集成",
            },
            {
              id: "2-3",
              title: "时空数据汇聚",
            },
            {
              id: "2-4",
              title: "GeoAI模型研发",
            },
          ],
        },
        {
          id: "3",
          title: "模板中心",
          children: [
            {
              id: "3-1",
              title: "零售",
            },
            {
              id: "3-2",
              title: "餐饮",
            },
            {
              id: "3-3",
              title: "电商",
            },
            {
              id: "3-4",
              title: "互联网",
            },

            {
              id: "3-5",
              title: "物流",
            },
            {
              id: "3-6",
              title: "制造",
            },
            {
              id: "3-7",
              title: "金融",
            },
          ],
        },
      ],
    };
  },
  created() {
    // 页面刷新选中当前路由123
    this.activeIndex = location.pathname;
  },
  methods: {
    selectSolution(item) {
      this.selectSolutionId = item.id;
    },
    goRouter(itemChildren) {
      if (itemChildren.router) {
        this.$router.push(itemChildren.router);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-leave-active,
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-30px);
  height: 30%;
  opacity: 0;
}

//解决方案
.productFeatures:hover .productFeaturesbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.productFeaturesbox {
  width: 140px;
  padding: 8px 0;
  background: white;
  box-shadow: 0 3px 10px 0 #161b2726;
  border-radius: 4px;

  position: fixed;
  top: 76px;
  left: 200px;
  display: none;
  cursor: default;
  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-family: HarmonyOS_Sans;
    font-weight: SC;
    font-size: 14px;
    color: #242627;
    text-align: left;
    line-height: 14px;
    cursor: pointer;
  }
  div[disabled] {
    color: #bbbec1;
  }
  div:hover {
    background: #ebecf0;
  }
}

.solution:hover .solutionbox {
  display: flex;
  justify-content: center;
}

.solutionbox {
  width: 100%;
  background: white;
  box-shadow: 0 3px 10px 0 #161b2726;
  position: fixed;
  top: 76px;
  left: 0px;
  display: none;
  cursor: default;
  .leftbox {
    flex-shrink: 0;
    padding: 40px 20px 40px 60px;
    width: 240px;
    background: #ffffff;
    box-shadow: 0 3px 10px 0 #161b2726;
    display: flex;
    flex-direction: column;
    gap: 10px;
    div {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      font-family: HarmonyOS_Sans;
      font-weight: 500;
      font-size: 16px;
      color: #393c40;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
    }
    div:hover,
    div[select] {
      background: #f1f2f6;
    }
  }
  .rightbox {
    flex-grow: 1;
    padding: 40px 140px;
    & > div {
      flex-wrap: wrap;
      div {
        width: 25%;
        cursor: pointer;
        font-family: HarmonyOS_Sans;
        font-weight: SC;
        font-size: 16px;
        color: #393c40;
      }

      div:hover {
        color: #0075cd;
      }
      div[disabled] {
        color: #bbbec1;
      }
    }
  }
}

.openability:hover .openabilitybox {
  display: flex;
  justify-content: center;
}
.openabilitybox {
  width: 100%;
  height: 432px;
  background: white;
  box-shadow: 0 3px 10px 0 #161b2726;
  position: fixed;
  top: 76px;
  left: 0px;
  display: none;
  cursor: default;
  .openabilityline {
    flex: 1 1;
    text-align: center;
    .openabilityimg {
      margin-left: 35%;
      text-align: left;
      height: 65px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .line-title {
      font-size: 18px;
      color: #1e1e1e;
      line-height: 18px;
      font-weight: 600;
      margin-left: 35%;
      text-align: left;
    }
    .click {
      color: #59595a;
      font-size: 16px;
      line-height: 16px;
      margin-top: 24px;
      cursor: pointer;
      margin-left: 35%;
      text-align: left;
    }
    .click:hover {
      color: #0f80dc;
    }
    .line-item {
      color: #999999;
      font-size: 16px;
      line-height: 16px;
      margin-top: 24px;
      cursor: pointer;
      margin-left: 35%;
      text-align: left;
    }
    .line-item:hover {
      color: #0f80dc;
    }
  }
}

.cooperation:hover .cooperationbox {
  // height: flex;
  display: flex;
  justify-content: center;
}
.cooperationbox {
  width: 100%;
  height: 432px;
  background: white;
  box-shadow: 0 3px 10px 0 #161b2726;
  position: fixed;
  top: 76px;
  left: 0px;
  display: none;
  cursor: default;
  // overflow: hidden;
  .cooperationline {
    flex: 1 1;
    text-align: center;
    .cooperationimg {
      // width: 32px;
      margin-left: 40%;
      text-align: left;
      height: 65px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .line-title {
      font-size: 18px;
      color: #1e1e1e;
      line-height: 18px;
      font-weight: 600;
      margin-left: 40%;
      text-align: left;
    }
    .line-item {
      color: #999999;
      font-size: 16px;
      line-height: 16px;
      margin-top: 24px;
      cursor: pointer;
      margin-left: 40%;
      text-align: left;
    }
    .line-item:hover {
      color: #0f80dc;
    }
  }
}
li:hover {
  // color: #0F80DC !important;
  font-weight: 400;
  &::after {
    content: "";
    position: absolute;
    // width: 100%;
    width: calc(100% - 50px);
    height: 2px;
    background: #fff;
    top: 75%;
    // left: calc(50% - 9px);
    left: 0;
  }
}
/*二級菜單懸停樣式*/
.el-menu--horizontal .el-menu .el-menu-item:hover {
  color: #0f80dc !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
  display: flex;
}

/* 服务与支持下移 */
#servicesAndSupport {
  position: relative;
  padding-right: 50px;
  /deep/ .el-submenu__title {
    border-bottom: none;
    color: #fff !important; /* 服务与支持选中颜色 */
    height: 76px;
    line-height: 76px;
    font-size: 16px;
    &:hover {
      background-color: transparent;
      // border-bottom: 1px solid #fff;
      font-weight: 400;
    }
  }
}

/* 服务与支持左间距 */
/deep/ .el-submenu__title {
  padding: 0;
  i {
    display: none;
  }
}

/* logo图标 */
.iconfont {
  font-size: 34px;
  color: #ffffff;
}

/* logo图标下划线 */
.el-menu--horizontal > li:first-child {
  border-bottom: none;
}

/* logo标签选中 */
i {
  color: #fff !important;
}

/* 菜单左右间距 start */
/deep/ .el-menu-item {
  padding: 0;
  // margin-right: 50px !important;
  padding-right: 50px;
  display: inline-block;
  font-size: 16px;
  border-bottom: 0 !important;
  position: relative;
  &:first-child {
    &::after {
      display: none;
    }
  }
}
.is-active {
  position: relative;
  font-weight: 400;
  &:first-child {
    &::after {
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    // width: 100%;
    width: calc(100% - 50px);
    height: 2px;
    background: #fff;
    top: 75%;
    // left: calc(50% - 9px);
    left: 0;
  }
}
/deep/ .el-menu {
  background-color: #0272c9;
  &.el-menu--horizontal > .el-menu-item {
    height: 76px;
    line-height: 76px;
    color: #fff !important;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

/* 菜单左右间距 end */

.head {
  height: 76px;
  margin-bottom: 1px;
  // width: 100vw;
  width: 100%;
  /*color: #2F3133;*/
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: #0272c9;
  padding: 0 40px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .iconsousuo {
      font-size: 17px;
      cursor: pointer;
    }
    & > span {
      font-size: 17px;
      font-weight: 500;
      color: #ffffff;
      line-height: 23px;
      position: relative;
      margin-left: 43px;
      &::after {
        content: "";
        position: absolute;
        height: 32px;
        width: 1px;
        background: rgba(255, 255, 255, 0.31);
        left: -21px;
        top: 50%;
        margin-top: -16px;
      }
    }
    .language {
      width: 58px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 22px;
      span {
        font-size: 14px;
        // font-weight: 500;
        color: rgba(255, 255, 255, 0.37);
        line-height: 19px;
        cursor: pointer;
        &.active {
          color: #fff;
        }
      }
    }

    .login {
      span {
        font-family: HarmonyOS_Sans;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

a {
  display: inline-block;
  height: 55px;
}
</style>
