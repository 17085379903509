<template>
  <div class="home">
    <div class="home-background"></div>

    <div class="banner">
      <div class="banner-center">
        <div class="banner-title">
          <span class="text">企业专属地图，感知态势、洞察趋势</span>
          <!-- <img src="@/assets/images/triangle.png" alt="" /> -->
        </div>

        <div class="banner-img">
          <img src="@/assets/images/homeBanner.png" alt="" />
        </div>
      </div>

      <!-- <div class="banner-right">
        <img src="@/assets/images/c.png" alt="" />
      </div> -->
    </div>

    <div class="content">
      <div class="test">
        <div class="test-left">
          <div class="first-level">
            空间数据服务
          </div>
          <div class="second-level">
            解决空间数据获取成本高、处理能力差等问题，实现自动化地址编码、坐标系切换等操作。
          </div>

          <el-button class="btn">了解更多 →</el-button>
        </div>
        <div class="test-img">
          <img src="@/assets/images/kongjianshujufuwu.png" alt="" />
        </div>
      </div>

      <div class="test">
        <div class="test-img">
          <img src="@/assets/images/ditukeshihua.png" alt="" />
        </div>
        <div class="test-left">
          <div class="first-level">
            地图可视化
          </div>
          <div class="second-level">
            具备二三维一体化的数据渲染能力，支持将二维地图数据、卫星影像、三维建模、业务数据等空间数据在同一张地图显示。
          </div>

          <el-button class="btn">了解更多 →</el-button>
        </div>
      </div>

      <div class="test" style="margin-bottom: 80px;">
        <div class="test-left">
          <div class="first-level">
            空间分析计算
          </div>
          <div class="second-level">
            提供丰富的基于矢量、栅格数据的空间分析能力，融合各维度数据进行空间关系和空间特征分析，实现对生产要素的空间位置描述、分析和预测。
          </div>

          <el-button class="btn">了解更多 →</el-button>
        </div>
        <div class="test-img">
          <img src="@/assets/images/kongjianfenxijisuan.png" alt="" />
        </div>
      </div>

      <div
        style="
        background: rgb(241, 242, 246,0.4);
        width: 100%;
        display: flex;
        justify-content: center;
        "
      >
        <div class="test">
          <div class="test-img">
            <img src="@/assets/images/zhinengkongjianyingyong.png" alt="" />
          </div>
          <div class="test-left">
            <div class="first-level">
              智能空间应用
            </div>
            <div class="second-level">
              综合运用数据连接、空间分析、低代码等平台能力，赋能用户快速创建业务应用小程序，最大程度挖掘数据价值。
            </div>

            <el-button class="btn">了解更多 →</el-button>
          </div>
        </div>
      </div>

      <div
        style="
        background: rgb(241, 242, 246,0.4);
        width: 100%;
        display: flex;
        justify-content: center;
        "
      >
        <div class="test2">
          <div class="test2-left">
            <div>
              <img src="@/assets/images/zhuantishujujiansuo.png" alt="" />
            </div>
            <div class="test2-title">
              <div class="first-level">
                专题数据检索
              </div>
              <div class="second-level">
                提供丰富的在线数据集，包括公共数据、遥感数据等，用户可以轻松使用增强其空间分析能力。
              </div>
              <a>了解更多 →</a>
            </div>
          </div>

          <div class="test2-right">
            <div>
              <img src="@/assets/images/changjinghuamokuai.png" alt="" />
            </div>
            <div class="test2-title">
              <div class="first-level">
                场景化模块
              </div>
              <div class="second-level">
                深度剖析场景，综合运用多种空间分析算法生成的专题地图，赋能企业经营决策。
              </div>
              <a>了解更多 →</a>
            </div>
          </div>
        </div>
      </div>

      <div
        style="
        background-image: linear-gradient(180deg, #F1F2F6 0%, #FDFDFF 100%);
        width: 100%;
        display: flex;
        justify-content: center;
        "
      >
        <div class="test3">
          <div class="test3-title">
            <div class="title1">面向场景的时空化解决方案</div>
            <div class="title2">
              基于数据可视化、空间分析等方面能力快速搭建场景应用
            </div>
          </div>

          <div class="test3-content">
            <div class="content1">
              <div class="content-img">
                <!-- <img src="@/assets/images/zhihuicanying.png" alt="" /> -->
              </div>
              <div class="content-title">
                <div class="content-title1">智慧餐饮</div>
                <div class="content-title2">
                  提升门店选址、客户运营、供应链管理等场景数字化能力，提升餐饮品牌营销获客、成本控制能力
                </div>
              </div>
            </div>

            <div class="content2">
              <div class="content-img">
                <!-- <img src="@/assets/images/zhihuinongye.png" alt="" /> -->
              </div>
              <div class="content-title">
                <div class="content-title1">智慧农业</div>
                <div class="content-title2">
                  结合卫星遥感影像、气象数据实现从播种到收获的可视化管理，实现农作物病虫害预测、产量预估
                </div>
              </div>
            </div>

            <div class="content3">
              <div class="content-img">
                <!-- <img src="@/assets/images/zhihuiyuanqu.png" alt="" /> -->
              </div>
              <div class="content-title">
                <div class="content-title1">智慧园区</div>
                <div class="content-title2">
                  实现园区安防、物业、日常管理的可视化、智慧化统筹运营，让园区管理更安全、更高效、更节能
                </div>
              </div>
            </div>

            <div class="content4">
              <div class="content-img">
                <!-- <img src="@/assets/images/zhihuigongdi.png" alt="" /> -->
              </div>
              <div class="content-title">
                <div class="content-title1">智慧工地</div>
                <div class="content-title2">
                  实现人机料环等全域生产要素的可视化指挥调度，全面提升工地安全管控能力和施工管理效能
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bg flex flex-col justify-between">
      技术底座
      <technical-base></technical-base>
      应用
      <app-view></app-view>
    </div>
    生态市场
    <ecology-market></ecology-market>
    解决方案
    <solution></solution>
    服务保障
    <service-assure></service-assure> -->

    <!-- 企业 -->
    <div style="width: 100%;display: flex;justify-content: center;">
      <div style="width: 1160px; ">
        <enterprise></enterprise>
      </div>
    </div>
    <!-- 联系我们 -->
    <contact-us></contact-us>
  </div>
</template>

<script>
import TechnicalBase from "./TechnicalBase";
import AppView from "./AppView";
import EcologyMarket from "./EcologyMarket";
import Solution from "./Solution";
import ServiceAssure from "./ServiceAssure";
import Enterprise from "./Enterprise";
import ContactUs from "./ContactUs";

export default {
  name: "home",
  components: {
    TechnicalBase,
    AppView,
    EcologyMarket,
    Solution,
    ServiceAssure,
    Enterprise,
    ContactUs,
  },
  data() {
    return {
      bannerList: [
        { img: require("@/assets/images/h_banner.png"), color: "#020709" },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.home {
  .home-background {
    width: 100%;
    height: 580px;
    background-image: url("~@/assets/images/homeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &/deep/.banner {
    height: 845px;
    background-image: linear-gradient(180deg, #0272c91a 0%, #ffffff00 100%);
    display: flex;
    justify-content: center;

    .banner-center {
      .banner-title {
        position: relative;
        text-align: center;
        margin: 80px 0 60px 0;
        .text {
          font-family: HarmonyOS_Sans;
          font-weight: 400;
          font-size: 36px;
          color: #000000;
          letter-spacing: 0;
        }
      }

      .banner-img {
        img {
          height: 585px;
        }
      }
    }

    // .banner-right {
    //   margin-top: -56px;
    // }
  }

  &/deep/.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1180px;
    .test {
      width: 1180px;
      display: flex;
      justify-content: space-between;
      padding-top: 80px;
      .test-left {
        width: 510px;
        padding-top: 80px;

        .first-level {
          font-family: HarmonyOS_Sans;
          font-weight: 500;
          font-size: 28px;
          color: #1e1e1e;
        }
        .second-level {
          height: 52px;
          font-family: HarmonyOS_Sans;
          font-weight: 400;
          font-size: 16px;
          color: #797c80;
          letter-spacing: 0;
          line-height: 26px;
          margin: 22px 0 82px 0;
        }

        .btn {
          width: 161px;
          height: 56px;
          background: #0f80dc;
          span {
            font-family: HarmonyOS_Sans;
            font-weight: 400;
            font-size: 20px;
            color: #ffffff;
          }
        }
      }

      .test-img {
        img {
          width: 590px;
          height: 400px;
          object-fit: contain;
        }
      }
    }

    .test2 {
      width: 1180px;
      display: flex;
      justify-content: space-between;
      padding: 80px 0;
      .test2-left,
      .test2-right {
        width: 570px;
        background: #ffffff;
        box-shadow: 0 3px 10px 0 #161b2726;
        .test2-title {
          width: 510px;
          padding: 40px 30px;

          .first-level {
            font-family: HarmonyOS_Sans;
            font-weight: 500;
            font-size: 28px;
            color: #1e1e1e;
          }
          .second-level {
            height: 52px;
            font-family: HarmonyOS_Sans;
            font-weight: 400;
            font-size: 16px;
            color: #797c80;
            letter-spacing: 0;
            line-height: 26px;
            margin-top: 22px;
          }
          a {
            display: block;
            height: 28px;
            font-family: HarmonyOS_Sans;
            font-weight: 500;
            font-size: 16px;
            color: #0f80dc;
            letter-spacing: 0;
            line-height: 28px;
            margin-top: 22px;
          }
        }
      }
      img {
        width: 570px;
        height: 360px;
        object-fit: contain;
      }
    }

    .test3 {
      width: 1180px;
      padding: 80px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .test3-title {
        margin-bottom: 80px;
        .title1 {
          font-family: HarmonyOS_Sans;
          font-weight: 400;
          font-size: 36px;
          color: #000000;
        }
        .title2 {
          font-family: HarmonyOS_Sans;
          font-weight: 400;
          font-size: 16px;
          color: #797c80;
          letter-spacing: 0;
          margin-top: 18px;
          padding: 0 16px;
        }
      }

      .test3-content {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        .content-title {
          width: 400px;
          margin-top: 15px;
          .content-title1 {
            font-family: HarmonyOS_Sans;
            font-weight: 500;
            font-size: 24px;
            color: #393c40;
            letter-spacing: 0;
          }
          .content-title2 {
            font-family: HarmonyOS_Sans;
            font-weight: 400;
            font-size: 14px;
            color: #797c80;
            letter-spacing: 0;
            line-height: 22px;
            margin-top: 10px;
          }
        }
        .content-img {
          width: 54px;
          height: 54px;
          margin-right: 20px;
        }
        .content1 {
          display: flex;
          padding: 19px 8.5px 54px 20px;
          border-bottom: 1px solid #dce3e9;

          background-image: url("~@/assets/images/zhcy.png");
          background-repeat: no-repeat;
          background-size: 0;

          .content-img {
            background-image: url("~@/assets/images/智慧餐饮.svg");
            background-size: cover;
          }
        }

        .content1:hover {
          background-size: auto;

          .content-img {
            background-image: url("~@/assets/images/智慧餐饮备份.svg");
          }

          .content-title {
            .content-title1,
            .content-title2 {
              color: #ffffff;
            }
          }
        }

        .content2 {
          display: flex;
          padding: 19px 0 54px 30.5px;
          border-bottom: 1px solid #dce3e9;
          border-left: 1px solid #dce3e9;

          background-image: url("~@/assets/images/zhny.png");
          background-position-x: right;
          background-repeat: no-repeat;
          background-size: 0;

          .content-img {
            background-image: url("~@/assets/images/智慧农业.svg");
            background-size: cover;
          }
        }

        .content2:hover {
          background-size: auto;
          .content-img {
            background-image: url("~@/assets/images/智慧农业备份.svg");
          }

          .content-title {
            .content-title1,
            .content-title2 {
              color: #ffffff;
            }
          }
        }

        .content3 {
          display: flex;
          padding-left: 20px;
          padding: 39px 8.5px 0 22px;
          border-radius: 4px;
          background-image: url("~@/assets/images/zhyq.png");
          background-position-y: bottom;
          background-repeat: no-repeat;
          background-size: 0;
          .content-img {
            background-image: url("~@/assets/images/智慧园区.svg");
            background-size: cover;
          }
        }

        .content3:hover {
          background-size: auto;
          .content-img {
            background-image: url("~@/assets/images/智慧园区备份.svg");
          }

          .content-title {
            .content-title1,
            .content-title2 {
              color: #ffffff;
            }
          }
        }

        .content4 {
          display: flex;
          padding: 39px 0 0 30.5px;
          border-left: 1px solid #dce3e9;

          background-image: url("~@/assets/images/zhgd.png");
          background-position-x: right;
          background-position-y: bottom;
          background-repeat: no-repeat;
          background-size: 0;
          .content-img {
            background-image: url("~@/assets/images/智慧工地.svg");
            background-size: cover;
          }
        }
        .content4:hover {
          background-size: auto;
          .content-img {
            background-image: url("~@/assets/images/智慧工地备份.svg");
          }

          .content-title {
            .content-title1,
            .content-title2 {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .bg {
    height: 1391px;
    background-image: linear-gradient(180deg, #f1f2f6 0%, #fdfdff 100%);
    box-sizing: border-box;
    padding-bottom: 60px;
  }
}
</style>
