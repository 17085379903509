<template>
  <div class="main">
    <div class="content">
      <div class="index_tit flex flex-col justify-between align-center">
        请联系我们, 您将获得全程支持咨询服务
      </div>
      <div class="flex justify-center">
        <router-link to="/contactus"
          ><el-button class="btn">联系我们</el-button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  methods: {},
}
</script>

<style lang="less" scoped>
.main {
  box-sizing: border-box;
  padding-top: 88px;
  height: 289px;
  // background-color: #edf1f4;
  background-image: url('~@/assets/images/位图.png');
}
.index_tit {
  color: #000;
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
}
.btn {
  width: 161px;
  height: 56px;
  background: #0f80dc !important;
  padding: 0 !important;
  color: #fff !important;
  margin-top: 33px;
  border-color: transparent !important;
  border-radius: 1px;
  font-size: 20px;
}
</style>
